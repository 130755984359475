import { useState, useEffect } from "react"
import { useAddNewFuelMutation } from "./fuelsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
import { CARS } from "../../config/cars"
import useAuth from "../../hooks/useAuth"
import Modal from "../../components/Modal"
import { Button, Container, Form } from "react-bootstrap"

const VALID_NUM_REGEX = /^(0|([1-9][0-9]?|1[01][0-9]|120))(?:\.[0-9]+)?$/

const NewFuelForm = () => {

    const [addNewFuel, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewFuelMutation();

    const navigate = useNavigate()
    const { username } = useAuth()

    const [carPlate, setCarPlate] = useState('')
    const [kilometers, setKilometers] = useState('')
    const [fuelDate, setFuelDate] = useState('')
    const [gasLiters, setGasLiters] = useState('')
    const [gasPrice, setGasPrice] = useState('')
    const [moneyAmount, setMoneyAmount] = useState('')
    const [user, setUser] = useState(username)
    const [gasFull, setGasFull] = useState(false)
    const [validNums, setValidNums] = useState(false)

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Validate number inputs
    useEffect(() => {
        const isValid =
            VALID_NUM_REGEX.test(gasLiters) &&
            VALID_NUM_REGEX.test(moneyAmount) &&
            VALID_NUM_REGEX.test(gasPrice);
        setValidNums(isValid);
    }, [gasLiters, gasPrice, moneyAmount])

    useEffect(() => {
        setUser(username);
    }, [username]);

    useEffect(() => {
        if (isSuccess) {
            setCarPlate('')
            setKilometers('')
            setFuelDate('')
            setGasLiters('')
            setGasPrice('')
            setMoneyAmount('')
            setUser('')
            setGasFull(false)
            navigate('/dashboard/fuels', { state: { message: 'Το καύσιμο προστέθηκε με επιτυχία.' } })
        }
    }, [isSuccess, navigate])

    const onCarPlateChange = e => setCarPlate(e.target.value)
    const onFuelDateChange = e => setFuelDate(e.target.value)
    const onKilometeresChange = e => setKilometers(e.target.value)
    const onGasLitersChange = e => setGasLiters(e.target.value)
    const onGasPriceChange = e => setGasPrice(e.target.value)
    const onGasFullChange = e => setGasFull(e.target.checked)
    const onMoneyAmountChange = e => setMoneyAmount(e.target.value)

    const canSave = [validNums].every(Boolean) && !isLoading

    const onAddNewFuelClick = async (e) => {
        e.preventDefault()

        try {
            await addNewFuel({ carPlate, fuelDate, kilometers, gasLiters, gasPrice, moneyAmount, user, gasFull }).unwrap();
        } catch (error) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        }
    }

    const cars = CARS.map(car => {
        return (
            <option
                key={car.carPlate}
                value={car.carPlate}
            >{car.carPlate}</option>
        )
    })

    let content

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }


    content = (
        <Container>
            <Form onSubmit={onAddNewFuelClick}>
                <h2>Προσθήκη Καυσίμου</h2>

                <Form.Group className="mb-3" controlId="carPlate">
                    <Form.Label>Επιλογή Οχήματος(*)</Form.Label>
                    <Form.Select aria-label="carPlate" name="carPlate" value={carPlate} onChange={onCarPlateChange} required>
                        <option>Επιλογή Οχήματος</option>
                        {cars}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="fuelDate">
                    <Form.Label>Ημερομηνία Αναπλήρωσης(*)</Form.Label>
                    <Form.Control type="date" name='fuelDate' value={fuelDate} onChange={onFuelDateChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasLiters'>
                    <Form.Label>Λίτρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasLiters' value={gasLiters} onChange={onGasLitersChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='kilometers'>
                    <Form.Label>Χιλιόμετρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='kilometers' value={kilometers} onChange={onKilometeresChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasPrice'>
                    <Form.Label>Τιμή Καυσίμου(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasPrice' value={gasPrice} onChange={onGasPriceChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='moneyAmount'>
                    <Form.Label>Ποσό Πληρωμής(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasPrice' value={moneyAmount} onChange={onMoneyAmountChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasFull'>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="gasFull"
                        name="gasFull"
                        checked={gasFull}
                        onChange={onGasFullChange}
                        label="Full"
                    />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <p><i>Τα πεδία με αστερίσκο (*) είναι υποχρεωτικά!</i></p>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Button type="submit" title="Save"> <FontAwesomeIcon icon={faFloppyDisk} /> Προσθήκη</Button>
                </Form.Group>

            </Form>
            <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}>
                <p>Σφάλμα: {errorMessage}</p>
            </Modal>
        </Container>
    )

    return content
}

export default NewFuelForm