import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const scheduleAdapter = createEntityAdapter({})
const initialState = scheduleAdapter.getInitialState()

export const scheduleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchedule: builder.query({
            query: () => '/schedule',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedSchedule = responseData.map(schedule => {
                    schedule.id = schedule._id
                    return schedule
                })
                return scheduleAdapter.setAll(initialState, loadedSchedule)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Schedule', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Schedule', id }))
                    ]
                } else return [{type: 'Schedule', id: 'LIST'}]
            }
        }),
        addToSchedule: builder.mutation({
            query: initialScheduleData => ({
                url: '/schedule',
                method: 'POST',
                body: {
                    ...initialScheduleData,
                }
            }),
            invalidatesTags: [
                { type: 'Schedule', id: 'LIST' }
            ]
        }),
        updateSchedule: builder.mutation({
            query: initialItem => ({
                url: '/schedule',
                method: 'PATCH',
                body: {
                    ...initialItem,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Schedule', id: arg.id }
            ]
        })
    })
})

export const {
    useGetScheduleQuery,
    useAddToScheduleMutation,
    useUpdateScheduleMutation
} = scheduleApiSlice

export const selectScheduleResult = scheduleApiSlice.endpoints.getSchedule.select();

const selectScheduleData = createSelector(
    selectScheduleResult,
    scheduleResult => scheduleResult.data
)


