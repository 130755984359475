import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials } from '../../features/authentication/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://kolektiva-manager-backend.onrender.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    // console.log(args) // request url, method, body
    // console.log(api) // signal, dispatch, getState()
    let result = await baseQuery(args, api, extraOptions)

    // Handle status codes
    if (result?.error?.status === 403) {

        // send refresh token to get new access token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

        if (refreshResult?.data) {
            // store new token
            api.dispatch(setCredentials({ ...refreshResult.data }))

            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = 'Your login has expired. '
            }
            return refreshResult
        }
    }
    return result
}

// Create API
export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Fuels', 'Sampling', 'Mapping', 'Visit', 'Stores', 'User', 'Equipment', 'Schedule'],
    endpoints: builder => ({})
})