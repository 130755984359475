import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { CARS } from "../../config/cars"
import { useUpdateFuelMutation } from './fuelsApiSlice'
import { Button, Container, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"


const EditFuelForm = ({ fuel }) => {

    const [updateFuel, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateFuelMutation()

    const navigate = useNavigate()

    const formattedDate = new Date(fuel.fuelDate).toISOString().split('T')[0];

    const [carPlate, setCarPlate] = useState(fuel.carPlate)
    const [kilometers, setKilometers] = useState(fuel.kilometers)
    const [fuelDate, setFuelDate] = useState(formattedDate)
    const [gasLiters, setGasLiters] = useState(fuel.gasLiters)
    const [gasPrice, setGasPrice] = useState(fuel.gasPrice)
    const [moneyAmount, setMoneyAmount] = useState(fuel.moneyAmount)
    const [gasFull, setGasFull] = useState(fuel.gasFull)
    const [user, setUser] = useState(fuel.userID)

    const onCarPlateChange = e => setCarPlate(e.target.value)
    const onFuelDateChange = e => setFuelDate(e.target.value)
    const onKilometeresChange = e => setKilometers(e.target.value)
    const onGasLitersChange = e => setGasLiters(e.target.value)
    const onGasPriceChange = e => setGasPrice(e.target.value)
    const onGasFullChange = e => setGasFull(e.target.checked)
    const onMoneyAmountChange = e => setMoneyAmount(e.target.value)

    const cars = CARS.map(car => {
        return (
            <option
                key={car.carPlate}
                value={car.carPlate}
            >{car.carPlate}</option>
        )
    })

    useEffect(() => {
        if (isSuccess) {
            setCarPlate('')
            setKilometers('')
            setFuelDate('')
            setGasLiters('')
            setGasPrice('')
            setMoneyAmount('')
            setGasFull(false)
            navigate('/dashboard/fuels', { state: { message: 'Το καύσιμο ενημερώθηκε με επιτυχία.' } })
        }
    }, [isSuccess, navigate])

    const onUpdateFuel = async (e) => {
        e.preventDefault()
        await updateFuel({ id: fuel.id, carPlate, kilometers, fuelDate, gasLiters, gasPrice, moneyAmount, gasFull, user })
    }

    return (
        <Container>
            <Form onSubmit={onUpdateFuel}>
            <Form.Group className="mb-3" controlId="carPlate">
                    <Form.Label>Επιλογή Οχήματος(*)</Form.Label>
                    <Form.Select aria-label="carPlate" name="carPlate" value={carPlate} onChange={onCarPlateChange} required>
                        <option>Επιλογή Οχήματος</option>
                        {cars}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="fuelDate">
                    <Form.Label>Ημερομηνία Αναπλήρωσης(*)</Form.Label>
                    <Form.Control type="date" name='fuelDate' value={fuelDate} onChange={onFuelDateChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasLiters'>
                    <Form.Label>Λίτρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasLiters' value={gasLiters} onChange={onGasLitersChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='kilometers'>
                    <Form.Label>Χιλιόμετρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='kilometers' value={kilometers} onChange={onKilometeresChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasPrice'>
                    <Form.Label>Τιμή Καυσίμου(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasPrice' value={gasPrice} onChange={onGasPriceChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='moneyAmount'>
                    <Form.Label>Ποσό Πληρωμής(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasPrice' value={moneyAmount} onChange={onMoneyAmountChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasFull'>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="gasFull"
                        name="gasFull"
                        checked={gasFull}
                        onChange={onGasFullChange}
                        label="Full"
                    />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <p><i>Τα πεδία με αστερίσκο (*) είναι υποχρεωτικά!</i></p>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Button type="submit" title="Save"> <FontAwesomeIcon icon={faFloppyDisk} /> Προσθήκη</Button>
                </Form.Group>

            </Form>
        </Container>
    )
}

export default EditFuelForm