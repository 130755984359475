import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAddEquipmentMutation } from "./equipmentApiSlice"
import { useState } from "react"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
import { useLocation } from "react-router-dom"

export const AddEquipmentForm = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const URLstoreID = queryParams.get('storeID')

    const [addEquipment, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddEquipmentMutation()

    const [espressoMachine, setEspressoMachine] = useState('')
    const [numOfGroups, setNumOfGroups] = useState(0)
    const [machineCondition, setMachineCondition] = useState('')
    const [grinder, setGrinder] = useState('')
    const [grinderCondition, setGrinderCondition] = useState('')

    const onEspressoMachineChange = e => setEspressoMachine(e.target.value)
    const onNumOfGroupsChange = e => setNumOfGroups(e.target.value)
    const onMachineConditionChange = e => setMachineCondition(e.target.value)
    const onGrinderChange = e => setGrinder(e.target.value)
    const onGrinderConditionChange = e => setGrinderCondition(e.target.value)

    const onAddEquipment = async (e) => {
        e.preventDefault()
        await addEquipment({ storeID: URLstoreID, espressoMachine, numOfGroups, machineCondition, grinder, grinderCondition })
    }

    let content

    if (isLoading) {
        content = <p>Loading...</p>
    }

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    content = (
        <>
            <form className='fuel-form' onSubmit={onAddEquipment}>
                <div className='form-group'>
                    <label htmlFor='espressoMachine'>Μηχανή Καφέ</label>
                    <input
                        type='text'
                        id='espressoMachine'
                        name='espressoMachine'
                        value={espressoMachine}
                        onChange={onEspressoMachineChange}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='numOfGroups'>Group</label>
                    <input
                        type='number'
                        id='numOfGroups'
                        name='numOfGroups'
                        value={numOfGroups}
                        onChange={onNumOfGroupsChange}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='machineCondition'>Κατάσταση Μηχανής</label>
                    <input
                        type='text'
                        id='machineCondition'
                        name='machineCondition'
                        value={machineCondition}
                        onChange={onMachineConditionChange}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='grinder'>Μύλος</label>
                    <input
                        type='text'
                        id='grinder'
                        name='grinder'
                        value={grinder}
                        onChange={onGrinderChange}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='grinderCondition'>Κατάσταση Μύλου</label>
                    <input
                        type='text'
                        id='grinderCondition'
                        name='grinderCondition'
                        value={grinderCondition}
                        onChange={onGrinderConditionChange}
                    />
                </div>

                <button
                    type="submit"
                ><FontAwesomeIcon icon={faFloppyDisk} /> Αποθήκευση Εξοπλισμού</button>

            </form>
        </>
    )

    return content
}

export default AddEquipmentForm