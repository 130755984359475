import React from 'react'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ScheduleForm from './ScheduleForm'
import { useGetCoffeeStoresQuery } from '../coffeeStores/storesApiSlice'

const AddToSchedule = () => {

    const { id } = useParams()
    const userID = useAuth().id

    const { store } = useGetCoffeeStoresQuery('storesList', {
        selectFromResult: ({ data }) => ({
            store: data?.entities[id]
        })
    })

    if (store){
        return <ScheduleForm id={id} name={store.storeTitle} userID={userID} />
    }
    
}

export default AddToSchedule