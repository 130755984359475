import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAddNewVisitMutation } from "./visitApiSlice"
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { Button, Container, Form } from "react-bootstrap";

const NewVisitForm = () => {

    const { id } = useParams()
    const { username } = useAuth()

    const [addNewVisit, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewVisitMutation();

    const navigate = useNavigate();

    const [storeID, setStoreID] = useState(id)
    const [visitDate, setVisitDate] = useState('')
    const [visitType, setVisitType] = useState('')
    const [comments, setComments] = useState('')
    const [user, setUser] = useState(username)

    useEffect(() => {
        if (isSuccess) {
            setStoreID('')
            setComments('')
            setUser('')
            navigate(`/dashboard/coffeestores/${id}`, { state: { message: 'Η Επίσκεψη προστέθηκε στο κατάστημα.' } })
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        setUser(username);
    }, [username]);

    const onCommentsChange = e => setComments(e.target.value)
    const onVisitDateChange = e => setVisitDate(e.target.value)
    const onVisitTypeChange = e => setVisitType(e.target.value)

    const onAddNewVisitClick = async (e) => {
        e.preventDefault()
        await addNewVisit({
            storeID,
            visitDate,
            visitType,
            comments,
            user
        })
    }

    let content

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    content = (
        <Container>
            <Form className="fuel-form" onSubmit={onAddNewVisitClick}>
                <h2>Προσθήκη Επίσκεψης</h2>
                <Form.Group className="mb-3" controlId="visitDate">
                    <Form.Label>Ημερομηνία Επίσκεψης</Form.Label>
                    <Form.Control type="date" name='visitDate' value={visitDate} onChange={onVisitDateChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="visitType">
                    <Form.Label>Τύπος Επικοινωνίας</Form.Label>
                    <Form.Select aria-label="visitType" name='visitType' value={visitType} onChange={onVisitTypeChange}>
                        <option key='' value=''>Επιλεξτε Τύπο Επικοινωνίας</option>
                        <option key='ΤΗΛΕΦΩΝΙΚΗ' value='ΤΗΛΕΦΩΝΙΚΗ'>Τηλεφωνική</option>
                        <option key='ΕΠΙΣΚΕΨΗ' value='ΕΠΙΣΚΕΨΗ'>Επίσκεψη</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="comments">
                    <Form.Label>Σχόλια</Form.Label>
                    <Form.Control as="textarea" rows={4} name='comments' value={comments} onChange={onCommentsChange} autoComplete="off" />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Button type='submit'><FontAwesomeIcon icon={faFloppyDisk} />Αποθήκευση</Button>
                </Form.Group>
            </Form>
        </Container>
    )

    return content
}

export default NewVisitForm