import React, { useEffect, useState } from 'react'
import { storeTypesList } from '../../config/storeTypes'
import { Button, Container, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { useUpdateStoreMutation } from './storesApiSlice'
import { useNavigate } from 'react-router-dom'

const EditStoreForm = ({ store }) => {

    const [updateStore, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateStoreMutation()

    const navigate = useNavigate()

    const [prefecture, setPrefecture] = useState(store.prefecture)
    const [city, setCity] = useState(store.city)
    const [storeTitle, setStoreTitle] = useState(store.storeTitle)
    const [entryType, setEntryType] = useState(store.entryType)
    const [storeType, setStoreType] = useState(store.storeType)
    const [coffee, setCoffee] = useState(store.coffee)
    const [consumption, setConsumption] = useState(store.consumption)
    const [storeOwner, setStoreOwner] = useState(store.contactInformation.storeOwner)
    const [contactPhone, setContactPhone] = useState(store.contactInformation.contactPhone)
    const [contactEmail, setContactEmail] = useState(store.contactInformation.contactEmail)

    const onPrefectureChange = e => setPrefecture(e.target.value)
    const onCityChange = e => setCity(e.target.value)
    const onStoreTitleChange = e => setStoreTitle(e.target.value)
    const onEntryTypeChange = e => setEntryType(e.target.value)
    const onStoreTypeChange = e => setStoreType(e.target.value)
    const onCoffeeChange = e => setCoffee(e.target.value)
    const onConsumptionChange = e => setConsumption(e.target.value)
    const onStoreOwnerChange = e => setStoreOwner(e.target.value)
    const onContactPhoneChange = e => setContactPhone(e.target.value)
    const onContactEmailChange = e => setContactEmail(e.target.value)

    const [storeName, setStoreName] = useState(store.interest?.storeName)
    const [VAT, setVAT] = useState(store.interest?.VAT)
    const [contactType, setContactType] = useState(store.interest?.contactType)
    const [products, setProducts] = useState(store.interest?.products)

    const onStoreNameChange = e => setStoreName(e.target.value)
    const onVatChange = e => setVAT(e.target.value)
    const onContactTypeChange = e => setContactType(e.target.value)

    const onProductsChange = (event) => {
        const { value, checked } = event.target;
        setProducts(prev => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter(item => item !== value);
            }
        });
    };

    useEffect(() => {
        if (isSuccess) {
            navigate(`/dashboard/coffeestores/${store._id}`)
        }
    })

    const onUpdateStore = async (e) => {
        e.preventDefault()
        await updateStore({ id: store._id, prefecture, city, storeTitle, entryType, storeType, coffee, consumption, storeOwner, contactPhone, contactEmail, storeName, VAT, contactType, products })
    }

    return (
        <Container>
            <Form onSubmit={onUpdateStore}>
                <Form.Group className="mb-3" controlId="prefecture">
                    <Form.Label>Νομός(*)</Form.Label>
                    <Form.Control type="text" name='prefecture' value={prefecture} onChange={onPrefectureChange} placeholder="Νομός" required />
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="city">
                    <Form.Label>Πόλη(*)</Form.Label>
                    <Form.Control type="text" name='city' value={city} onChange={onCityChange} placeholder="Πόλη" required />
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="storeTitle">
                    <Form.Label>Όνομα Καταστήματος(*)</Form.Label>
                    <Form.Control type="text" name='storeTitle' value={storeTitle} onChange={onStoreTitleChange} placeholder="Όνομα Καταστήματος" required />
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="entryType">
                    <Form.Label>Καταχώρηση Από(*)</Form.Label>
                    <Form.Select aria-label="entryType" name='entryType' value={entryType} onChange={onEntryTypeChange} required>
                        <option key='' value=''>Επιλέξτε Τύπο Καταχώρησης</option>
                        <option key='ΧΑΡΤΟΓΡΑΦΗΣΗ' value='ΧΑΡΤΟΓΡΑΦΗΣΗ'>Χαρτογράφηση</option>
                        <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ'>Απευθείας Επικοινωνία</option>
                    </Form.Select>
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="storeType">
                    <Form.Label>Τύπος Καταστήματος(*)</Form.Label>
                    <Form.Select aria-label="storeType" name='storeType' value={storeType} onChange={onStoreTypeChange} required>
                        <option key='' value=''>Επιλέξτε Τύπο Καταστήματος</option>
                        {storeTypesList}
                    </Form.Select>
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="coffee">
                    <Form.Label>Καφές(*)</Form.Label>
                    <Form.Control type="text" name='coffee' value={coffee} onChange={onCoffeeChange} placeholder="Καφές" required />
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="consumption">
                    <Form.Label>Κατανάλωση(*)</Form.Label>
                    <Form.Control type="number" name='consumption' value={consumption} onChange={onConsumptionChange} placeholder="Κατανάλωση" required />
                    <Form.Text className="text-muted">
                        Το πεδίο είναι υποχρεωτικό
                    </Form.Text>
                </Form.Group>
                <h2>Στοιχεία Επικοινωνίας</h2>
                <Form.Group className="mb-3" controlId="storeOwner">
                    <Form.Label>Ιδιοκτήτης Καταστήματος</Form.Label>
                    <Form.Control type="text" name='storeOwner' value={storeOwner} onChange={onStoreOwnerChange} placeholder="Ιδιοκτήτης Καταστήματος" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactPhone">
                    <Form.Label>Τηλέφωνο Επικοινωνίας</Form.Label>
                    <Form.Control type="number" name='contactPhone' value={contactPhone} onChange={onContactPhoneChange} placeholder="Τηλέφωνο Επικοινωνίας" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactEmail">
                    <Form.Label>Email Επικοινωνίας</Form.Label>
                    <Form.Control type="email" name='contactEmail' value={contactEmail} onChange={onContactEmailChange} placeholder="Email Επικοινωνίας" />
                </Form.Group>

                <h2>Εκδήλωση Ενδιαφέροντος</h2>
                <Form.Group className="mb-3" controlId="storeName">
                    <Form.Label>Επωνυμία Καταστήματος</Form.Label>
                    <Form.Control type="text" name='storeName' value={storeName} onChange={onStoreNameChange} placeholder="Επωνυμία Καταστήματος" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="VAT">
                    <Form.Label>Α.Φ.Μ.</Form.Label>
                    <Form.Control type="text" name='VAT' value={VAT} onChange={onVatChange} placeholder="Α.Φ.Μ." />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactType">
                    <Form.Label>Επαφή Με Την Εταιρεία</Form.Label>
                    <Form.Select aria-label="contactType" name='contactType' value={contactType} onChange={onContactTypeChange}>
                        <option key='' value=''>Επιλεξτε Τύπο Επαφής</option>
                        <option key='ΣΥΣΤΑΣΗ' value='ΜΕ ΣΥΣΤΑΣΗ'>ΜΕ ΣΥΣΤΑΣΗ</option>
                        <option key='ΔΟΚΙΜΗ' value='ΜΕ ΔΟΚΙΜΗ'>ΜΕ ΔΟΚΙΜΗ</option>
                        <option key='ΔΙΑΦΗΜΗΣΗ' value='ΜΕ ΔΙΑΦΗΜΗΣΗ'>ΜΕ ΔΙΑΦΗΜΗΣΗ</option>
                        <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ'>ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3' controlId='products'>
                    <Form.Label>Προιόντα Ενδιαφέροντος</Form.Label>

                    <Form.Group>
                        <Form.Check // prettier-ignore
                            inline
                            id="espresso"
                            name="espresso"
                            value='ESPRESSO'
                            onChange={onProductsChange}
                            label="Espresso"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="decaf"
                            name="decaf"
                            value='DECAF'
                            onChange={onProductsChange}
                            label="Decaf"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="filter-coffee"
                            name="filter-coffee"
                            value='Καφές Φίλτρου'
                            onChange={onProductsChange}
                            label="Καφές Φίλτρου"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="single-origin"
                            name="single-origin"
                            value='Single Origin'
                            onChange={onProductsChange}
                            label="Single Origin"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="greek-coffee"
                            name="greek-coffee"
                            value='Ελληνικός Καφές'
                            onChange={onProductsChange}
                            label="Ελληνικός Καφές"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="chocolate"
                            name="chocolate"
                            value='Σοκολάτα Κλασική'
                            onChange={onProductsChange}
                            label="Σοκολάτα Κλασική"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="chocolate-flavors"
                            name="chocolate-flavors"
                            value='Σοκολάτα Γεύσεις'
                            onChange={onProductsChange}
                            label="Σοκολάτα Γεύσεις"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="tea-flavors"
                            name="tea-flavors"
                            value='Τσάι Γεύσεις'
                            onChange={onProductsChange}
                            label="Τσάι Γεύσεις"
                        />
                        <Form.Check // prettier-ignore
                            inline
                            id="tea-bio"
                            name="tea-bio"
                            value='Τσάι Βιολογικά'
                            onChange={onProductsChange}
                            label="Τσάι Βιολογικά"
                        />
                    </Form.Group>

                </Form.Group>

                <Form.Group className='mb-3'>
                    <Button type='submit'><FontAwesomeIcon icon={faFloppyDisk} /> Ενημέρωση Στοιχείων</Button>
                </Form.Group>


            </Form>
        </Container>
    )
}

export default EditStoreForm