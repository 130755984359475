import { apiSlice } from "../../app/api/apiSlice";

export const visitApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // Removed getVisit endpoint
        addNewVisit: builder.mutation({
            query: initialVisitData => ({
                url: '/visits',
                method: 'POST',
                body: {
                    ...initialVisitData
                }
            }),
            invalidateTags: [
                { type: 'Visit', id: 'LIST' }
            ]
        })
    })
})


export const { 
    useAddNewVisitMutation
} = visitApiSlice

