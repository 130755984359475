import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const coffeeStoresAdapter = createEntityAdapter({})

const initialState = coffeeStoresAdapter.getInitialState()

export const coffeeStoresApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCoffeeStores: builder.query({
            query: () => '/coffeeStores',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedCoffeeStores = responseData.map(coffeeStore => {
                    coffeeStore.id = coffeeStore._id
                    return coffeeStore
                });
                return coffeeStoresAdapter.setAll(initialState, loadedCoffeeStores);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Stores', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Stores', id}))
                    ]
                } else return [{type: 'Stores', id: 'LIST'}]
            }
        }),
        addNewCoffeeStore: builder.mutation({
            query: initialCoffeeStoreData => ({
                url: '/coffeestores',
                method: 'POST',
                body: {
                    ...initialCoffeeStoreData,
                }
            }),
            invalidatesTags: [
                { type: 'Stores', id: 'LIST' }
            ]
        }),
        updateStore: builder.mutation({
            query: initialStore => ({
                url:'/coffeestores',
                method: 'PATCH',
                body: {
                    ...initialStore,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Stores', id: arg.id }
            ]
        })
    })
})

export const {
    useGetCoffeeStoresQuery,
    useAddNewCoffeeStoreMutation,
    useUpdateStoreMutation
} = coffeeStoresApiSlice

// returs the query resul oject
export const selectCoffeeStoresResult = coffeeStoresApiSlice.endpoints.getCoffeeStores.select();

// creates memoized selector
const selectCoffeeStoresData = createSelector(
    selectCoffeeStoresResult,
    coffeeStoresResult => coffeeStoresResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCoffeeStores,
    selectById: SelectCoffeeStoreById,
    selectIds: selectCoffeeStoreIds
    // Pass in a selector that returs the users slice of state
} = coffeeStoresAdapter.getSelectors(state => selectCoffeeStoresData(state) ?? initialState)