import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGasPump, faShop, faCirclePlus, faCircleUser, faCalendarDays, faFileLines } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';


const MainNav = ({ username, onLogoutClicked }) => {

    return (
        <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to='/dashboard/coffeestores'>
                    <img
                        src='/img/rk_logowhite.png'
                        height="30"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to='/dashboard/coffeestores'><FontAwesomeIcon icon={faShop} /> Καταστήματα</Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/fuels'><FontAwesomeIcon icon={faGasPump} /> Καύσιμα</Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/schedule'><FontAwesomeIcon icon={faCalendarDays} /> Πρόγραμμα</Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/updates'><FontAwesomeIcon icon={faFileLines} /> Ενημερώσεις</Nav.Link>
                        <NavDropdown title={<><FontAwesomeIcon icon={faCircleUser} /> {username}</>} id="basic-nav-dropdown">

                            <NavDropdown.Item href="/dashboard/coffeestores/new"><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Καταστήματος</NavDropdown.Item>

                            <NavDropdown.Item href="/dashboard/fuels/new"><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Καυσίμου</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/dashboard/user/edit">Αλλαγή Κωδικού</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={onLogoutClicked}>Αποσύνδεση</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MainNav