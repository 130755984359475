import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const fuelsAdapter = createEntityAdapter({})

const initialState = fuelsAdapter.getInitialState()

export const fuelsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFuels: builder.query({
            query: () => '/fuels',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedFuels = responseData.map(fuel => {
                    fuel.id = fuel._id
                    return fuel
                });
                return fuelsAdapter.setAll(initialState, loadedFuels);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Fuels', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Fuels', id}))
                    ]
                } else return [{type: 'Fuels', id: 'LIST'}]
            }
        }),
        addNewFuel: builder.mutation({
            query: initialFuelData => ({
                url: '/fuels',
                method: 'POST',
                body: {
                    ...initialFuelData,
                }
            }),
            invalidatesTags: [
                { type: 'Fuels', id: 'LIST' }
            ]
        }),
        updateFuel: builder.mutation({
            query: initialFuel => ({
                url: '/fuels',
                method: 'PATCH',
                body: {
                    ...initialFuel,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Fuels', id: arg.id }
            ]
        })
    })
})

export const {
    useGetFuelsQuery,
    useAddNewFuelMutation,
    useUpdateFuelMutation
} = fuelsApiSlice

// returs the query resul oject
export const selectFuelsResult = fuelsApiSlice.endpoints.getFuels.select();

// creates memoized selector
const selectFuelsData = createSelector(
    selectFuelsResult,
    fuelsResult => fuelsResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllFuels,
    selectById: SelectFuelById,
    selectIds: selectFuelIds
    // Pass in a selector that returs the users slice of state
} = fuelsAdapter.getSelectors(state => selectFuelsData(state) ?? initialState)

// Define a custom Selector to filter results based on id and car plate

export const selectByIdAndPlate = createSelector(
    [selectAllFuels, (state, fuelId) => fuelId, (state, fuelId, carPlate) => carPlate],
    (allFuels, fuelId, carPlate) => {
        return allFuels.find(fuel => fuel.id === fuelId && fuel.carPlate === carPlate)
    }
)