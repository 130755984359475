import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { useAddToScheduleMutation } from './scheduleApiSlice'
import { useGetUsersQuery } from '../users/userApiSlice'
import useAuth from '../../hooks/useAuth'

const ScheduleForm = ({ id, userID, name }) => {

    const { username } = useAuth()

    const [addToSchedule, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddToScheduleMutation()

    const {
        data: users,
        isLoading: isUsersLoading,
        isSuccess: isUsersSuccess,
        isError: isUsersError,
        error: usersError
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let userOptionList


    if (isUsersSuccess) {
        const userIDs = users.ids
        const userEntities = users.entities

        const userList = userIDs?.length
            ? userIDs.map(id => userEntities[id].username)
            : null

        userOptionList = userList.map(user => {
            return (
                <option
                    key={user}
                    value={user}
                >{user}</option>
            )
        })
    }

    const navigate = useNavigate()

    const [storeID, setStoreID] = useState(id)
    const [date, setDate] = useState('')
    const [storeName, setStoreName] = useState(name)
    const [visitType, setVisitType] = useState('Επίσκεψη')
    const [user, setUser] = useState(username)
    const [comments, setComments] = useState()
    const [status, setStatus] = useState('open')

    useEffect(() => {
        setUser(username)
        setStoreID(id)
        setStoreName(name)
        setStatus('open')
    }, [userID, id, name])

    const onDateChange = e => setDate(e.target.value)
    const onVisitTypeChange = e => setVisitType(e.target.value)
    const onUserChange = e => setUser(e.target.value)
    const onCommentsChange = e => setComments(e.target.value)

    useEffect(() => {
        if (isSuccess) {
            navigate('/dashboard/schedule')
        }
    }, [isSuccess, navigate])

    const onAddToSchedule = async (e) => {
        e.preventDefault()

        try {
            await addToSchedule({ storeID, date, storeName, visitType, user, comments, status })
        } catch (error) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
            return errorMessage
        }
    }

    return (
        <Container>
            <Form onSubmit={onAddToSchedule}>
                <Form.Group className='mb-3' controlId='storeName'>
                    <Form.Label>Όνομα Καταστήματος</Form.Label>
                    <Form.Control type='text' name='storeName' value={storeName} disabled></Form.Control>
                </Form.Group>
                <Form.Group className='mb-3' controlId='date'>
                    <Form.Label>Ημερομηνία Επόμενης Επίσκεψης</Form.Label>
                    <Form.Control type="date" name='date' value={date} onChange={onDateChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='user'>
                    <Form.Label>Πωλητής</Form.Label>
                    <Form.Select aria-label="user" name="user" value={user} onChange={onUserChange} required>
                        {userOptionList}
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3' controlId='visitType'>
                    <Form.Label>Τύπος Επίσκεψης</Form.Label>
                    <Form.Select aria-label="visitType" name="visitType" value={visitType} onChange={onVisitTypeChange} required>
                        <option disabled>Τύπος Επίσκεψης</option>
                        <option>Επίσκεψη</option>
                        <option>Δειγματισμός</option>
                        <option>Στήσιμο</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="comments">
                    <Form.Label>Σχόλια</Form.Label>
                    <Form.Control as="textarea" rows={4} name='comments' value={comments} onChange={onCommentsChange} autoComplete="off" />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Button type="submit" title="Save"> <FontAwesomeIcon icon={faFloppyDisk} /> Αποθήκευση</Button>
                </Form.Group>
            </Form>
        </Container>
    )
}

export default ScheduleForm