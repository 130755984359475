import React from 'react'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useGetFuelsQuery } from './fuelsApiSlice'
import EditFuelForm from './EditFuelForm'

const EditFuel = () => {
    const { id } = useParams()
    const { username } = useAuth()

    const {fuel} = useGetFuelsQuery('fuelList', {
        selectFromResult: ({ data }) => ({
            fuel: data?.entities[id]
        })
    })

    if (fuel && (fuel.userID?.username === username || username === 'g.varsamis')) {
        return <EditFuelForm fuel={fuel} />
    } else {
        return (<div>Δεν έχετε δικαίωμα επεξεργασίας αυτής της καταχώρησης.</div>)
    }

}

export default EditFuel