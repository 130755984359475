import { useGetScheduleQuery } from '../features/schedule/scheduleApiSlice'

const useGetNextVisit = ( id ) => {

    const {
        data: schedule,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetScheduleQuery('scheduleList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let next

    if (isSuccess) {
        const { ids, entities } = schedule

        const hasVisit = ids.find(visitId => entities[visitId]?.storeID === id && entities[visitId]?.status === 'open');
        if (hasVisit) {
            next = entities[hasVisit].date
        }
    }

    if (next) {
        return new Date(next).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })
    } else {
        return "Δεν υπάρχουν προγραμματισμένες επισκέψεις"
    }
}

export default useGetNextVisit