import React from 'react'
import { useGetCoffeeStoresQuery } from './storesApiSlice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

const CoffeeStore = ({ coffeeStoreId, index }) => {
    const { coffeeStore } = useGetCoffeeStoresQuery('storesList', {
        selectFromResult: ({ data }) => ({
            coffeeStore: data?.entities[coffeeStoreId]
        }),
    })

    const navigate = useNavigate()

    if (coffeeStore) {

        const created = new Date(coffeeStore.createdAt).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })

        const handleView = () => navigate(`/dashboard/coffeestores/${coffeeStoreId}`)
        const handleAddVisit = () => navigate(`/dashboard/coffeestores/addvisit/${coffeeStoreId}`)
        const handleAddSampling = () => navigate(`/dashboard/coffeestores/addSampling/${coffeeStoreId}`)

        return (
            <tr>
                <td>{index + 1}</td>
                <td>{created}</td>
                <td>{coffeeStore.prefecture}</td>
                <td>{coffeeStore.city}</td>
                <td>{coffeeStore.storeTitle}</td>
                <td>{coffeeStore.entryType}</td>
                <td>{coffeeStore.storeType ?? coffeeStore.storeType}</td>
                <td>{coffeeStore.coffee}</td>
                <td>{coffeeStore.consumption}</td>
                <td>{coffeeStore.visits?.length ?
                    <Button variant="success" size="sm" onClick={handleView} style={{ width: '100%', fontSize: '0.85rem' }}><FontAwesomeIcon icon={faEye} /> {new Date(coffeeStore.visits[coffeeStore.visits.length-1].visitDate).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}</Button>
                    :
                    <Button size="sm" style={{ width: '100%', fontSize: '0.85rem' }} onClick={handleAddVisit}><FontAwesomeIcon icon={faPlus} /> Προσθήκη Επίσκεψης</Button>
                }</td>

                <td>
                    {
                        coffeeStore.samplings?.length ?
                            <Button variant="success" size="sm" onClick={handleView} style={{ width: '100%', fontSize: '0.85rem' }}><FontAwesomeIcon icon={faEye} /> {new Date(coffeeStore.samplings[coffeeStore.samplings.length-1].samplingDate).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}</Button>
                            :
                            <Button size="sm" style={{ width: '100%', fontSize: '0.85rem' }} onClick={handleAddSampling}><FontAwesomeIcon icon={faPlus} /> Προσθήκη Δειγματισμού </Button>
                    }
                </td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faBars} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href={`/dashboard/coffeestores/${coffeeStoreId}`}>Προβολή</Dropdown.Item>
                            <Dropdown.Item href={`/dashboard/coffeestores/addsampling/${coffeeStoreId}`}>Προσθήκη Δειγματισμού</Dropdown.Item>
                            <Dropdown.Item href={`/dashboard/coffeestores/addvisit/${coffeeStoreId}`}>Προσθήκη Επίσκεψης</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </td>
            </tr>
        )
    }

}

export default CoffeeStore