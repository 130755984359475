import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const equipmentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addEquipment: builder.mutation({
            query: initialEquipmentData => ({
                url: '/coffeestores/addEquipment',
                method: 'POST',
                body: {
                    ...initialEquipmentData,
                }
            }),
            invalidatesTags: [
                {type: 'Equipment', id: 'LIST'}
            ]
        })
    })
})

export const {
    useAddEquipmentMutation
} = equipmentApiSlice