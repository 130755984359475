import { useGetFuelsQuery } from "./fuelsApiSlice"
import Fuel from "./Fuel"
import { CARS } from "../../config/cars"
import { useEffect, useState } from "react"
import useFuelConsumption from "../../hooks/useFuelConsumption"
import { useLocation } from "react-router-dom"
import Table from 'react-bootstrap/Table';
import { Container, Form } from "react-bootstrap"

const FuelsList = () => {
  const location = useLocation();
  const [visibleMessage, setVisibleMessage] = useState(location.state?.message);

  // get Data from query
  const {
    data: fuels,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetFuelsQuery('fuelsList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {
    // If there's a message, set a timer to clear it after 10 seconds
    if (visibleMessage) {
      const timer = setTimeout(() => {
        setVisibleMessage(null); // Clears the message
      }, 10000); // 10 seconds

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [visibleMessage]);

  const cars = CARS.map(car => {
    return (
      <option
        key={car.carPlate}
        value={car.carPlate}
      >{car.carPlate}</option>
    )
  })

  const [carPlate, setCarPlate] = useState('ΕΡΙ 2176')

  const onCarPlateChange = e => setCarPlate(e.target.value)

  let content

  if (isLoading) content = <p>Loading...</p>

  if (isError) {
    content = <p>{error?.data?.message}</p>
  }
  const consumptionByFuelId = useFuelConsumption(carPlate);

  if (isSuccess) {
    const { ids, entities } = fuels;

    let filteredIds
    if (carPlate) {
      filteredIds = ids.filter(carID => entities[carID].carPlate === carPlate)
    }

    // Sort the filteredIds based on fuelDate
    const sortedFuels = filteredIds.sort((a, b) => {
      // Compare the fuelDate of two entities using their IDs
      const dateA = new Date(entities[a].fuelDate);
      const dateB = new Date(entities[b].fuelDate);
      return dateA - dateB;
    });

    console.log(filteredIds)

    const tableContent = ids?.length
      ? sortedFuels.map((fuelId, index) => <Fuel key={fuelId} fuelId={fuelId} carPlate={carPlate} index={index} consumption={consumptionByFuelId[fuelId]} editButton={index === sortedFuels.length - 1}/>)
      : null

    content = (
      <Container>
        {visibleMessage && <div className='successMessage'>{visibleMessage}</div>}

        <Form.Group className="m-3" controlId="carPlate">
          <Form.Label>Επιλογή Οχήματος</Form.Label>
          <Form.Select aria-label="carPlate" name="carPlate" value={carPlate} onChange={onCarPlateChange} required>
            <option>Επιλογή Οχήματος</option>
            {cars}
          </Form.Select>
        </Form.Group>
        <Table responsive="sm" borderless striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Ημερομηνία</th>
              <th>Όχημα</th>
              <th>Χιλιόμετρα</th>
              <th>Λίτρα</th>
              <th>Τιμή / Λίτρο</th>
              <th>Ποσό Πληρωμής</th>
              <th>Οδηγός</th>
              <th>Full</th>
              <th>Κατανάλωση</th>
              <th>Επεξεργασία</th>
            </tr>
          </thead>

          <tbody>
            {tableContent}
          </tbody>

        </Table>
      </Container>
    )
  }

return content
}

export default FuelsList