import { Outlet } from 'react-router-dom';
import Header from './Header';
import { Alert } from 'react-bootstrap';

const Dashboard = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default Dashboard