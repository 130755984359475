import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAddNewSamplingMutation } from './samplingApiSlice'
import useAuth from '../../hooks/useAuth'
import { Button, Form, Container } from 'react-bootstrap'

const NewSamplingForm = () => {

    const { id } = useParams()
    const { username } = useAuth()

    const navigate = useNavigate();

    const [addNewSampling, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useAddNewSamplingMutation();

    const [storeID, setStoreID] = useState(id)
    const [user, setUser] = useState(username)
    const [samplingDate, setSamplingDate] = useState('')
    const [coffee, setCoffee] = useState('')
    const [comments, setComments] = useState('')

    useEffect(() => {
        setUser(username);
    }, [username]);

    useEffect(() => {
        if (isSuccess) {
            setStoreID('')
            setUser('')
            setSamplingDate('')
            setCoffee('')
            setComments('')
            navigate(`/dashboard/coffeestores/${id}`, { state: { message: 'Ο δειγματισμός προστέθηκε στο κατάστημα.' } })
        }
    }, [isSuccess, navigate])

    const onCoffeeChange = e => setCoffee(e.target.value)
    const onCommentsChange = e => setComments(e.target.value)
    const onSamplingDateChange = e => setSamplingDate(e.target.value)

    const onAddNewSamplingClick = async (e) => {
        e.preventDefault()
        await addNewSampling({
            storeID,
            user,
            samplingDate,
            coffee,
            comments
        })
    }

    let content

    content = (
        <Container>
            <Form onSubmit={onAddNewSamplingClick}>
                <Form.Group className="mb-3" controlId="samplingDate">
                    <Form.Label>Ημερομηνία Δειγματισμού</Form.Label>
                    <Form.Control type="date" name='samplingDate' value={samplingDate} onChange={onSamplingDateChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="coffee">
                    <Form.Label>Προιόντα Δειγματισμού</Form.Label>
                    <Form.Control type="text" name='coffee' value={coffee} onChange={onCoffeeChange} placeholder="Προιόντα Δειγματισμού" />
                    <Form.Text className="text-muted">
                        Χρησιμοποιήστε κόμμα(,) για να διαχωρίσετε τα προϊόντα.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="comments">
                    <Form.Label>Σχόλια Δειγματισμού</Form.Label>
                    <Form.Control as="textarea" rows={4} name='comments' value={comments} onChange={onCommentsChange} autoComplete="off" />
                </Form.Group>
                <Button type="submit" title="Save"> <FontAwesomeIcon icon={faFloppyDisk} /> Προσθήκη</Button>
            </Form >
        </Container>
    )
    return content
}

export default NewSamplingForm