import { useNavigate, useParams } from 'react-router-dom';
import { useGetCoffeeStoresQuery } from '../coffeeStores/storesApiSlice';
import { useState } from 'react';
import AddEquipmentForm from '../equipment/AddEquipmentForm';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import { Col, Container, Row, Stack } from 'react-bootstrap';
import useGetNextVisit from '../../hooks/useGetNextVisit';

const StoreProfile = () => {

    const navigate = useNavigate()
    const { id } = useParams()

    const { coffeeStore } = useGetCoffeeStoresQuery('storesList', {
        selectFromResult: ({ data }) => ({
            coffeeStore: data?.entities[id]
        }),
    })

    const [showEquipmentForm, setShowEquipmentForm] = useState(false)
    const onShowEquipmentClick = () => setShowEquipmentForm(true)
    const handleEdit = () => navigate(`/dashboard/coffeestores/edit/${id}`)
    const scheduleVisit = () => navigate(`/dashboard/schedule/add/${id}`)
    const handleAddVisit = () => navigate(`/dashboard/coffeestores/addvisit/${id}`)
    const handleAddSampling = () => navigate(`/dashboard/coffeestores/addSampling/${id}`)

    const nextVisit = useGetNextVisit(id)

    if (coffeeStore) {

        let sampling
        let visit
        let equipment

        if (coffeeStore.samplings?.length) {
            sampling = (
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ημερομηνία</th>
                            <th>Πωλητής</th>
                            <th>Καφές Δειγματισμού</th>
                            <th>Σχόλια Δειγματισμού</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coffeeStore.samplings?.map((sampling, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(sampling.samplingDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                <td>{sampling.userID.username}</td>
                                <td>{sampling.coffee}</td>
                                <td>{sampling.comments}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )
        } else {
            sampling = (
                <p>Δεν βρέθηκαν δειγματισμοί.</p>
            )
        }

        if (coffeeStore.visits?.length) {
            visit = (
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Τύπος Επικοινωνίας</th>
                            <th>Ημερομηνία</th>
                            <th>Πωλητής</th>
                            <th>Σχόλια</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coffeeStore.visits.map((visit, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{visit.visitType}</td>
                                <td>{new Date(visit.visitDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                <td>{visit.userID?.username}</td>
                                <td>{visit.comments}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            );
        } else {
            visit = (
                <p>Δεν βρήθεκαν επισκέψεις.</p>
            )
        }

        if (coffeeStore.equipment) {
            equipment = (
                <>
                    <div className='row pt-2'>
                        <div className='col-3'>
                            Μηχανή
                        </div>
                        <div className='col-6 bold'>
                            {coffeeStore.equipment.espressoMachine}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-3'>
                            Group
                        </div>
                        <div className='col-6 bold'>
                            {coffeeStore.equipment.numOfGroups}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-3'>
                            Κατάσταση Μηχανής
                        </div>
                        <div className='col-6 bold'>
                            {coffeeStore.equipment.machineCondition}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-3'>
                            Μύλος
                        </div>
                        <div className='col-6 bold'>
                            {coffeeStore.equipment.grinder}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-3'>
                            Κατάσταση Μύλου
                        </div>
                        <div className='col-6 bold'>
                            {coffeeStore.equipment.grinderCondition}
                        </div>
                    </div>
                </>
            )
        } else {
            equipment = (
                <>

                    <button onClick={onShowEquipmentClick}>Προσθήκη Εξοπλισμού</button>
                    {showEquipmentForm && <AddEquipmentForm />}
                </>
            )
        }

        return (
            <Container>
                <h2>Καταστήματα {`>>  ${coffeeStore.storeTitle}`}</h2>
                <Button variant="secondary" size="sm" onClick={handleEdit}>Επεξεργασία</Button>
                <Button variant="secondary" size="sm" onClick={scheduleVisit}>Προγραμματισμός Επίσκεψης</Button>
                Επομενη Επίσκεψη: {nextVisit}
                <Row>

                    <Col sm={3} className='bg-light pt-2 mt-2'>
                        <Col sm={12}>
                            <h5>Βασικές Πληροφορίες</h5>
                        </Col>

                        <Col sm={6}>
                            Νομός
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.prefecture}
                        </Col>

                        <Col sm={6} className="pt-3">
                            Πόλη
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.city}
                        </Col>

                        <Col sm={6} className="pt-3">
                            Τύπος Καταστήματος
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.storeType}
                        </Col>

                        <Col sm={6} className="pt-3">
                            Καταχώρηση
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.entryType}
                        </Col>

                        <Col sm={6} className="pt-3">
                            Καφές
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.coffee}
                        </Col>
                        <Col sm={6} className="pt-3">
                            Κατανάλωση
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.consumption} kg / Έτος
                        </Col>
                        <Col sm={6} className="pt-3">
                            Πωλητής
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.userID?.username}
                        </Col>

                        <Col sm={12} className='pt-3'>
                            <h5>Πληροφορίες Επικοινωνίας</h5>
                        </Col>

                        <Col sm={6} className="pt-3">
                            Ιδιοκτήτης
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.contactInformation.storeOwner}
                        </Col>
                        <Col sm={6} className="pt-3">
                            Τηλέφωνο
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.contactInformation.contactPhone}
                        </Col>
                        <Col sm={6} className="pt-3">
                            Email
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.contactInformation.contactEmail}
                        </Col>

                        <Col sm={12} className='pt-3'>
                            <h5>Εκδήλωση Ενδιαφέροντος</h5>
                        </Col>

                        <Col sm={6} className="pt-3">
                            Επωνυμία
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.intrest?.storeName}
                        </Col>
                        <Col sm={6} className="pt-3">
                            ΑΦΜ
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.interest?.VAT}
                        </Col>
                        <Col sm={6} className="pt-3">
                            Προιόντα
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.interest?.products}
                        </Col>
                        <Col sm={6} className="pt-3">
                            Επαφή Με Την Εταιρεία
                        </Col>
                        <Col sm={6} className='fw-bold'>
                            {coffeeStore.interest?.contactType}
                        </Col>
                    </Col>
                      

                    <Col sm={9} className='pt-2 mt-2'>
                        <Row>
                            <Stack direction="horizontal" gap={3}>
                                <h4>Ιστορικό Επικοινωνίας</h4>
                                <button type="button" onClick={handleAddVisit} className="btn btn-outline-primary btn-sm">Προσθήκη Επικοινωνίας</button>
                            </Stack>

                            {visit}
                        </Row>

                        <Row>
                            <Stack direction="horizontal" gap={3}>
                                <h4>Δειγματισμοί</h4>
                                <button type="button" onClick={handleAddSampling} className="btn btn-outline-primary btn-sm">Προσθήκη Δειγματισμού</button>
                            </Stack>
                            {sampling}
                        </Row>
                        <Row>
                            <Stack direction="horizontal" gap={3}>
                                <h4>Εξοπλισμός</h4>
                                <button type="button" className="btn btn-outline-primary btn-sm">Προσθήκη Εξοπλισμού</button>
                            </Stack>
                            {equipment}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (!coffeeStore) {
        return <div>Loading.. </div>
    }
}

export default StoreProfile