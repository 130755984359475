import React, { useState, useEffect } from 'react'
import { useGetCoffeeStoresQuery } from './storesApiSlice'
import CoffeeStore from './CoffeeStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { storeTypesList } from '../../config/storeTypes'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Button, Col, Row } from 'react-bootstrap'


const CoffeeStoresList = () => {

  const location = useLocation();
  const [visibleMessage, setVisibleMessage] = useState(location.state?.message);
  const navigate = useNavigate()

  // Get Coffee Stores Data From Query
  const {
    data: coffeeStores,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetCoffeeStoresQuery('storesList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  // Add Support for search
  const [prefecture, setPrefecture] = useState('')
  const [city, setCity] = useState('')
  const [storeType, setStoreType] = useState('')
  const [entryType, setEntryType] = useState('')
  const [date, setDate] = useState('')
  const [hasVisit, setHasVisit] = useState('')
  const [hasSampling, setHasSampling] = useState('')
  const [coffee, setCoffee] = useState('')

  const onPrefectureChange = e => setPrefecture(e.target.value)
  const onCityChange = e => setCity(e.target.value)
  const onStoreTypeChange = e => setStoreType(e.target.value)
  const onEntryTypeChange = e => setEntryType(e.target.value)
  const onDateChange = e => setDate(e.target.value)
  const onHasVisitChange = e => setHasVisit(e.target.checked)
  const onHasSamplingChange = e => setHasSampling(e.target.checked)
  const onCoffeeChange = e => setCoffee(e.target.value)

  let content

  if (isLoading) content = <p>Loading...</p>

  if (isError) {
    content = <p>{error?.data?.message}</p>
  }

  useEffect(() => {
    // If there's a message, set a timer to clear it after 10 seconds
    if (visibleMessage) {
      const timer = setTimeout(() => {
        setVisibleMessage(null); // Clears the message
      }, 10000); // 10 seconds

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [visibleMessage]);

  const handleButton = () => navigate('/dashboard/coffeestores/new')
  if (isSuccess) {
    const { ids, entities } = coffeeStores

    // Filter Stores to render
    let filteredIds
    const inputDate = new Date(date);
    filteredIds = ids.filter(storeId => {

      const entity = entities[storeId];
      const createdAt = new Date(entity.createdAt);
      // First, ensure all text-based conditions are met

      // First, check if the storeType filter is applied and if any entity matches it
      const isStoreTypeApplied = storeType.trim() !== ''; // Assuming storeType is a string
      const isEntryTypeApplied = entryType.trim() !== '';
      const matchesTextConditions =
        entities[storeId].prefecture.toLowerCase().includes(prefecture.toLowerCase()) &&
        entities[storeId].city.toLowerCase().includes(city.toLowerCase()) &&
        entities[storeId].coffee?.toLowerCase().includes(coffee.toLowerCase());

      const isDateSpecified = date && !isNaN(inputDate.getTime());

      const matchedDateConditions =
        !isDateSpecified || (createdAt.getFullYear() === inputDate.getFullYear() &&
          createdAt.getMonth() === inputDate.getMonth() &&
          createdAt.getDate() === inputDate.getDate());


      // Then, if hasVisit is true, also check that the visits array exists and is not empty
      const matchesVisitCondition = !hasVisit || (entities[storeId].visits && entities[storeId].visits.length > 0);
      const matchesSamplingCondition = !hasSampling || (entities[storeId].samplings && entities[storeId].samplings.length > 0);

      // Check the storeType condition only if it's applied and there are matches
      const matchesStoreType = isStoreTypeApplied ? entities[storeId]?.storeType?.toLowerCase().includes(storeType.toLowerCase()) : true;
      const mathcesEntryType = isEntryTypeApplied ? entities[storeId]?.entryType?.toLowerCase().includes(entryType.toLowerCase()) : true;

      return matchesTextConditions && matchesVisitCondition && matchesSamplingCondition && matchesStoreType && matchedDateConditions && mathcesEntryType;
    })



    const tableContent = ids?.length
      ? filteredIds.map((coffeeStoreId, index) => <CoffeeStore key={coffeeStoreId} coffeeStoreId={coffeeStoreId} index={index} />)
      : null

    content = (
      <>
        {visibleMessage && <div className='successMessage'>{visibleMessage}</div>}
        <Button onClick={handleButton} className='m-3'>Προσθήκη Καταστήματος</Button>
        <Table striped hover responsive className='.fs-custom'>
          <thead>
            <tr>
              <th></th>
              <th><Form.Control
                type='date'
                id='date'
                name='date'
                value={date}
                onChange={onDateChange}
              />

              </th>
              <th>
                <Form.Control
                  type="text"
                  id="prefecture"
                  name="prefecture"
                  autoComplete="off"
                  value={prefecture}
                  onChange={onPrefectureChange}
                  placeholder='Νομός'
                />
              </th>
              <td>
                <Form.Control
                  type="text"
                  id="city"
                  name="city"
                  autoComplete="off"
                  value={city}
                  onChange={onCityChange}
                  placeholder='Πόλη'
                />
              </td>
              <td></td>
              <td>
                <Form.Control
                  as="select"
                  id="entryType"
                  name="entryType"
                  value={entryType}
                  onChange={onEntryTypeChange}
                >
                  <option key='---' value=''>Όλα</option>
                  <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ'>Απευθείας</option>
                  <option key='ΧΑΡΤΟΓΡΑΦΗΣΗ' value='ΧΑΡΤΟΓΡΑΦΗΣΗ'>Χαρτογράφηση</option>
                </Form.Control>
              </td>
              <td><Form.Control
                as="select"
                id="storeType"
                name="storeType"
                value={storeType}
                onChange={onStoreTypeChange}
              >
                <option
                  key='---'
                  value=''
                >ΟΛΑ</option>
                {storeTypesList}
              </Form.Control></td>
              <td><Form.Control
                id="coffee"
                name="coffee"
                autoComplete="off"
                value={coffee}
                onChange={onCoffeeChange}
                placeholder='Καφές'
              />
              </td>
              <td></td>
              <td><Form.Group controlId="hasVisit">
                <Form.Check
                  type="checkbox"
                  id="hasVisit"
                  label="Έχει Επισκέψεις"
                  checked={hasVisit}
                  onChange={onHasVisitChange}
                />
              </Form.Group>
              </td>
              <td> <Form.Group controlId="hasSampling">
                <Form.Check
                  type="checkbox"
                  id="hasSampling"
                  name="hasSampling"
                  label="Έχει Δειγματισμούς"
                  checked={hasSampling}
                  onChange={onHasSamplingChange}
                />
              </Form.Group></td>
              <td></td>

            </tr>
            <tr>
              <th>#</th>
              <th>Ημερομηνία Εγγραφής</th>
              <th>Νομός</th>
              <th>Πόλη</th>
              <th>Όνομα Καταστήματος</th>
              <th>Καταχώρηση</th>
              <th>Τύπος Καταστήματος</th>
              <th>Καφές</th>
              <th >Κατανάλωση (Εκτίμηση)</th>
              <th >Επίσκεψη</th>
              <th >Δειγματισμός</th>
              <th >Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
      </>
    )
  }

  return content
}

export default CoffeeStoresList