import React from 'react'
import { Container, Table } from 'react-bootstrap'
import { useGetScheduleQuery } from './scheduleApiSlice'
import ScheduleItem from './ScheduleItem'

const ScheduleList = () => {

    const {
        data: schedule,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetScheduleQuery('scheduleList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content
    if (isLoading) content = <p>Loading...</p>

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = schedule

        let openStatus = ids.filter(id => entities[id].status === 'open')
        const tableContentOpen = ids?.length
            ? openStatus.map((id, index) => <ScheduleItem id={id} index={index} />)
            : null

        let closedStatus = ids.filter(id => entities[id].status !== 'open')
        const tableContentClosed = ids.length
            ? closedStatus.map((id, index) => <ScheduleItem id={id} index={index} />)
            : null

        content = (
            <Container>
                <h4>Επερχόμενα</h4>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ημερομηνία</th>
                            <th>Όνομα Καταστήματος</th>
                            <th>Τύπος Επίσκεψης</th>
                            <th>Πωλητής</th>
                            <th>Ενέργειες</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContentOpen}
                    </tbody>
                </Table>

                <h4>Ολοκληρωμένες</h4>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ημερομηνία</th>
                            <th>Όνομα Καταστήματος</th>
                            <th>Τύπος Επίσκεψης</th>
                            <th>Πωλητής</th>
                            <th>Ενέργειες</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContentClosed}
                    </tbody>
                </Table>
            </Container>
        )

    }
    return content
}

export default ScheduleList