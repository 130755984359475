import { apiSlice } from "../../app/api/apiSlice";

export const samplingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewSampling: builder.mutation({
            query: initialSamplingsData => ({
                url: '/samplings',
                method: 'POST',
                body: {
                    ...initialSamplingsData
                }
            }),
            invalidatesTags: [{ type:'Sampling', id: 'LIST' }]
        })
    })
})

export const {
    useAddNewSamplingMutation
} = samplingsApiSlice