import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './features/authentication/Login';
import Dashboard from './components/Dashboard';
import Index from './features/authentication/Index';
import FuelsList from './features/fuels/FuelsList';
import NewFuelForm from './features/fuels/NewFuelForm';
import NewVisitForm from './features/visits/NewVisitForm';
import CoffeeStoresList from './features/coffeeStores/CoffeeStoresList';
import NewStoreForm from './features/coffeeStores/NewStoreForm';
import NewSamplingForm from './features/sampling/NewSamplingForm';
import Prefetch from './features/authentication/Prefetch';
import PersistLogin from './features/authentication/PersistLogin';
import StoreProfile from './features/coffeeStores/StoreProfile';
import EditUser from './features/users/EditUser';
import AddUser from './features/users/AddUser';
import EditStore from './features/coffeeStores/EditStore';
import EditFuelForm from './features/fuels/EditFuelForm';
import EditFuel from './features/fuels/EditFuel';
import ScheduleList from './features/schedule/ScheduleList';
import ScheduleForm from './features/schedule/ScheduleForm';
import AddToSchedule from './features/schedule/AddToSchedule';
import Updates from './features/updates/Updates';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Login />} />
        <Route path='login' element={<Login />} />


        <Route element={<PersistLogin />}>
          <Route element={<Prefetch />}>

            <Route path='dashboard' element={<Dashboard />}>
              <Route index element={<Index />} />

              <Route path='fuels'>
                <Route index element={<FuelsList />} />
                <Route path='new' element={<NewFuelForm />} />
                <Route path='edit/:id' element={<EditFuel />} />
              </Route>

              <Route path='coffeestores'>
                <Route index element={<CoffeeStoresList />} />
                <Route path='new' element={<NewStoreForm />} />
                <Route path=':id' element={<StoreProfile />} />
                <Route path='edit/:id' element={<EditStore />} />
                <Route path='addvisit/:id' element={<NewVisitForm />} />
                <Route path='addsampling/:id' element={<NewSamplingForm />} />
              </Route>

              <Route path='schedule'>
                <Route index element={<ScheduleList />} />
                <Route path='add/:id' element={<AddToSchedule />} />
              </Route>

              <Route path='user'>
                <Route path='edit' element={<EditUser />} />
                <Route path='add' element={<AddUser />} />
              </Route>

              <Route path='updates' element={<Updates />} />

            </Route>

          </Route>

        </Route>
      </Route>
    </Routes>
  );
}

export default App;
