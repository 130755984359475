import { useGetFuelsQuery } from "../features/fuels/fuelsApiSlice";

const useFuelConsumption = (carPlate) => {
    const {
        data: fuels,
        isLoading,
        isError,
        error
    } = useGetFuelsQuery('fuelsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error?.data?.message}</div>;

    // Convert entities object into an array of fuels
    const allFuels = fuels.ids.map(id => fuels.entities[id]);

    // Filter fuels by car plate
    const filteredFuels = allFuels.filter(fuel => fuel.carPlate === carPlate);

    // Ensure fuels are sorted by creation date
    const sortedFuels = filteredFuels.sort((a, b) => new Date(a.fuelDate) - new Date(b.fuelDate));

    const consumptionByFuelId = {};
    let isFirst = false;
    let firstFullKm = 0
    let totalLitersForSegment = 0;

    sortedFuels.forEach((fuel, index) => {
        // Once we find a full tank, start the process
        if (fuel.gasFull) {
          if (!isFirst) {
            isFirst = true
            firstFullKm = fuel.kilometers
            totalLitersForSegment = 0
          } else {
            totalLitersForSegment += fuel.gasLiters
            let totalKm = fuel.kilometers - firstFullKm
            let consumption = (totalLitersForSegment / totalKm) * 100
            consumptionByFuelId[fuel.id] = parseFloat(consumption.toFixed(1));
            firstFullKm = fuel.kilometers
            totalLitersForSegment = 0

          }
        } else {
          totalLitersForSegment += fuel.gasLiters;
        } 
    });
    return consumptionByFuelId;
};
export default useFuelConsumption;
