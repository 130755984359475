import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSquareCheck, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { useGetFuelsQuery } from "./fuelsApiSlice";
import { useNavigate } from "react-router-dom";

const Fuel = ({ fuelId, index, consumption, editButton }) => {

    const navigate = useNavigate()

    const { fuel } = useGetFuelsQuery('fuelsList', {
        selectFromResult: ({ data }) => ({
            fuel: data?.entities[fuelId]
        }),
    })

    const handleEdit = () => navigate(`/dashboard/fuels/edit/${fuelId}`)

    if (fuel) {

        const created = new Date(fuel.fuelDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{created}</td>
                <td>{fuel.carPlate}</td>
                <td>{fuel.kilometers}</td>
                <td>{fuel.gasLiters}</td>
                <td>{fuel.gasPrice} €</td>
                <td>{fuel.moneyAmount} €</td>
                <td>{fuel.userID?.username}</td>
                <td>{fuel.gasFull ? <FontAwesomeIcon icon={faSquareCheck} /> : <FontAwesomeIcon icon={faSquareXmark} />}</td>
                <td>{consumption && `${consumption} lt / 100 km`} </td>
                <td>{editButton && ( // Check if it's the last item
                    <button onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                )}</td>
            </tr>
        )
    } else return null
}

export default Fuel