import React, { useState } from 'react'
import { useGetScheduleQuery, useUpdateScheduleMutation } from './scheduleApiSlice'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ScheduleItem = ({ id, index }) => {
    const { scheduleItem } = useGetScheduleQuery('scheduleList', {
        selectFromResult: ({ data }) => ({
            scheduleItem: data?.entities[id]
        })
    })

    const [show, setShow] = useState(false);

    const handleShowInfo = () => setShow(true)
    const handleClose = () => setShow(false)

    // Update Schedule Item Status
    // Start

    const navigate = useNavigate()

    const [updateSchedule, {
        isSuccess
    }] = useUpdateScheduleMutation()

    const updateStatus = async (e) => {
        e.preventDefault()
        await updateSchedule({id: id, status: 'closed' })
    }

    // Update Schedule Item Status
    // End

    if (scheduleItem) {

        const created = new Date(scheduleItem.date).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })
        return (
            <>
                <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{created}</td>
                    <td>{scheduleItem.storeName}</td>
                    <td>{scheduleItem.visitType}</td>
                    <td>{scheduleItem.userID?.username}</td>
                    <td>{scheduleItem.status === "open" ? <a href="#" onClick={updateStatus}>Ολοκληρώθηκε </a> : null} {/* Update Schedule Item Status */}
                    <a href="#" onClick={handleShowInfo}> Λεπτομέρειες</a></td>
                </tr>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Λεπτομέρειες Επίσκεψης</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{scheduleItem.comments}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Κλείσιμο
                        </Button>

                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default ScheduleItem