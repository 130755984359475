import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSendLogoutMutation } from "../features/authentication/authApiSlice";
import useAuth from "../hooks/useAuth";
import MainNav from "./MainNav";

const Header = () => {

  const navigate = useNavigate()

  const { username } = useAuth()

  const [sendLogout, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useSendLogoutMutation()

  useEffect(() => {
    if (isSuccess) navigate('/')
  }, [isSuccess, navigate])

  const onLogoutClicked = () => sendLogout()

  if (isLoading) return <p>Logging Out...</p>

  if (isError) return <p>Error: {error.data?.message}</p>


  return (
      <MainNav username={username} onLogoutClicked={onLogoutClicked}/>
  )
}

export default Header